import React from 'react';
import {
  makeStyles,
  Button,
  Caption1,
  Text,
  tokens,
  Subtitle1,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@fluentui/react-components";
import { MoreHorizontal20Regular } from "@fluentui/react-icons";
import { Card, CardHeader, CardPreview } from "@fluentui/react-components";
import accountStore from '../../redux/Account/AccountRedux';

export default class SupportCard extends React.Component {

  public constructor(props:any) {
    super(props);

    const reduxState = accountStore.getState();
    this.state = {
      account: reduxState.account
    };
  };

  public render() {
    const state = this.state as any;

    return (
      <div>
        <section>
          <Card>
            <CardHeader
              header={<Text weight="semibold">Customer Support Overview</Text>}
              description={
                <Caption1></Caption1>
              }
              action={
                <Button
                  appearance="transparent"
                  icon={<MoreHorizontal20Regular />}
                  aria-label="More options"
                  onClick={() => {
                  
                  }}
                />
              }
            />

            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Organisation</b>
                  </TableCell>
                  <TableCell>
                    {state.account.organisation_name}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <b>Primary Contact</b>
                  </TableCell>
                  <TableCell>
                    {state.account.first_name} {state.account.last_name}
                  </TableCell>
                </TableRow>


                <TableRow>
                  <TableCell>
                    <b>Email Address</b>
                  </TableCell>
                  <TableCell>
                    {state.account.email_address}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <br />

            <Button  
              style={{width: 170}}
              onClick={() => {
         
              }}
            >Support Center</Button>
          </Card>
        </section>
      </div>
    );
  }

}