import React from 'react';
import { Stack, StackItem, Text, IconButton, TextField, Modal, DefaultButton, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles } from '@fluentui/react';
import {ReactComponent as BrandLogoSvg} from './../../../assets/branding/brand.svg';
import { Button, Spinner, ButtonProps, Dialog, DialogSurface, DialogTitle, DialogContent, DialogActions, Field, Input } from "@fluentui/react-components";

import backgroundImage from './../../../assets/backgrounds/default.jpg';
import Global_Error from '../../../lib/GlobalError/GlobalError';
import Authentication_Service from '../../../lib/Authentication/AuthenticationService';
import Authentication_Request_Response from '../../../lib/Authentication/AuthenticationService';

export default class LoginView extends React.Component {

  private styles: any = {};

  constructor(props: any) {
    super(props);

    this.styles = {
      wrapper: {
        width: '100vw',
        height: '100vh',

        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        overflow: 'hidden',
        justifyContent: 'flex-end',

        zIndex: 999999
      },

      loginWrapper: {
        
      },

      logo: {
        marginLeft: -310
      },

      pwResetModalContainer: {
        width: 500,
        padding: 25,
        paddingTop: 0
      }
    }

    this.state = {
      displayPasswordResetModal: false,
      is_submitting: false,

      username: "",
      password: ""
    };
  }

  public authentication_request = async (): Promise<void> => {
    this.setState({
      is_submitting: true
    });

    // const globalError = new Global_Error()
    // globalError.display_user_visible_error({
    //   error_title: "Invalid Login",
    //   error_content: "Your username or password is incorrect. Please try again. If you have forgotten your password, consider resetting it."
    // });

    // globalError.dialog_events.on("dialog_close", () => {
    //   this.setState({
    //     is_submitting: false
    //   });
    //   globalError.dialog_events.removeAllListeners();
    // });
    
    const state = this.state as any;
    const authenticationResult: Authentication_Request_Response = await Authentication_Service.authentication_request(
      state.username,
      state.password
    );

    // check if we have a server related issue...
    if(authenticationResult.has_error) {
      const globalError = new Global_Error()
      globalError.display_user_visible_error({
        error_title: "Login Error",
        error_content: "There seems to be an issue connecting your request to our authentication service. Please try again, if the issue persists then please contact support@121digital.co.uk"
      });

      globalError.dialog_events.on("dialog_close", () => {
        globalError.dialog_events.removeAllListeners();
        this.setState({
          is_submitting: false
        });
      });
    }
    else {
      // free up the form if bad request...
      if(!authenticationResult.auth_result) {
        const globalError = new Global_Error()
        globalError.display_user_visible_error({
          error_title: "Invalid Login",
          error_content: "The email or password that you have entered is incorrect. Please try again, or attempt to reset your password."
        });
  
        globalError.dialog_events.on("dialog_close", () => {
          globalError.dialog_events.removeAllListeners();
          this.setState({
            is_submitting: false
          });
        });
        
        this.setState({
          is_submitting: false
        });
      }
      else {
        window.location.assign("/dashboard");
      }
    }
  }

  public hidePasswordResetModal = () => {
    this.setState({
      displayPasswordResetModal: false
    });
  }

  public displayPasswordResetModal = () => {
    this.setState({
      displayPasswordResetModal: true
    });
  }

  public render() {
    const state = this.state as any;

    return (
      <div style={this.styles.wrapper}>
        <form>
          <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 400, margin: '0 auto', marginTop: '35vh', padding: 25, backgroundColor: "white", borderRadius: 4} }}>
            <StackItem>
              <BrandLogoSvg height={70} style={this.styles.logo} />
            </StackItem>
            <StackItem>
              <Field label={"Email Address"}>
                <Input
                  disabled={state.is_submitting}
                  size='large'
                  value={state.username}
                  onChange={(event) => {
                    this.setState({
                      username: event.target.value
                    });
                  }}
                />
              </Field>
            </StackItem>
            <StackItem>
              <Field label={"Password"}>
                <Input
                  disabled={state.is_submitting}
                  size="large"
                  value={state.password}
                  type='password'
                  onChange={(event) => {
                    this.setState({
                      password: event.target.value
                    })
                  }}
                />
              </Field>
            </StackItem>
            <StackItem>
            <Link disabled={state.is_submitting} onClick={this.displayPasswordResetModal}>
              Forgotten password
            </Link>
            </StackItem>
            <StackItem>
              <Button onClick={this.authentication_request} appearance='primary'>
                {state.is_submitting? (
                  <Spinner size='tiny' />
                ): (
                  "Login"
                )}
              </Button>
              <Button type='button' style={{marginLeft: 15}}>Create Account</Button>
            </StackItem>
          </Stack>
        </form>

        <Dialog open={state.displayPasswordResetModal}>
          <DialogSurface>
            <DialogTitle>Reset your account password</DialogTitle>
            <DialogContent>
              <p>
                Enter your account email address, and a password reset link
                will be sent. Follow the instructions on the password reset
                email to continue.
              </p>

              <div style={{marginTop: 15}}>
              <Field label="Email Address" required>
                <Input />
              </Field>
              </div>

              <br />
            </DialogContent>
            <DialogActions>
              <Button type="button" appearance='primary'>Submit</Button>
              <Button onClick={this.hidePasswordResetModal}>Close</Button>
            </DialogActions>
          </DialogSurface>
        </Dialog>


        {/* <Modal
            isOpen={(this.state as any).displayPasswordResetModal}
            onDismiss={this.hidePasswordResetModal}
            isBlocking={false}
            containerClassName="modalContainer"
        > 

            <div style={this.styles.pwResetModalContainer}>
                <h2>Reset your account password</h2>

                <Button appearance='primary'>Submit</Button>
                <Button style={{marginTop: 25, marginLeft: 10}} onClick={this.hidePasswordResetModal}>
                  Cancel
                </Button>
            </div>
        </Modal> */}
      </div>
    );
  }

}