import React from 'react';
import SafeArea from '../../components/SafeArea/SafeArea';
import { PrimaryButton } from '@fluentui/react';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import BlockedFeature from '../../components/BlockedFeature/BlockedFeature';

initializeIcons();

export default class SMSDashboardView extends React.Component {

  constructor(props: any) {
    super(props);
  }

  public render() {
    return (
      <SafeArea>
        <BlockedFeature />
      </SafeArea>
    );
  }

}