import * as env from './../../env/env';

export default class Log {

  public static dev_log(logContent: any): void {
    if(env.default.type == "development") {
      console.log(logContent);
    }
  }

  public static dev_warning(logContent: any): void {
    if(env.default.type == "development") {
      console.warn(logContent);
    }
  }

  public static dev_error(logContent: any): void {
    if(env.default.type == "development") {
      console.error(logContent);
    }
  }

}