import { EventEmitter } from "events";
import { Error_Modal_Props } from "../../components/ErrorDialog/ErrorDialog";
import globalErrorStore from "../../redux/GlobalError/GlobalErrorRedux";

export interface Global_Error_Type {
  error_title: string,
  error_content: string
}

export default class Global_Error {

  public dialog_events: EventEmitter = new EventEmitter();

  public constructor() {
    /*
      subscribe to changes in the dialog change and pass them into
      our local event emitter, so that the UI can be hooked into the
      global error display dialog and react to changes such as close...
    */
   globalErrorStore.subscribe(() => {
    const globalErrorState = globalErrorStore.getState();
    if(!globalErrorState.global_error.display) {
      this.dialog_events.emit("dialog_close");
    }
   })
  }

  public display_user_visible_error(error: Global_Error_Type) {
    globalErrorStore.dispatch({
     type: "GLOBAL_ERROR_UPDATE", 
     payload: {
      display: true,
      error_title: error.error_title,
      error_body: error.error_content
    }});

  }

}