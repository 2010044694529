import { Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { Text } from '@fluentui/react-components';
import React from 'react';

export interface FullscreenLoader_Props {
  display: boolean
};

export default class FullscerenLoader extends React.Component <FullscreenLoader_Props> {

  private styles: any = {};

  public constructor(props: any) {
    super(props);

    this.styles = {
      wrapper: {
        position: 'absolute',
        top: 0,
        left: 0,

        width: "100%",
        height: "100%",

        backgroundColor: "white",
        zIndex: 9999999
      }
    };
  }

  public render() {
    if(this.props.display) {
      return (
        <div style={this.styles.wrapper}>
          <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { height: '100vh' } }}>
            <Spinner size={SpinnerSize.large} />
            <Text style={{marginTop: 20}}>Loading 121 Console, please wait...</Text>
          </Stack>
        </div>
      );

    }
    else {
      return (<div />);
    }
  }

}