const rootState = {
  global_error: {
    error_title: "",
    error_body: "",
    display: false
  }
};

const rootReducer = (state = rootState, action: any) => {
  switch(action.type) {
    
    case "GLOBAL_ERROR_UPDATE":
      return { ...state, global_error: action.payload}

    default:
      return state;
  }
}

export default rootReducer;