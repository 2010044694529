import React from 'react';

export default class Spacer extends React.Component <{size: string}> {
  
  constructor(props: any) {
    super(props);

    // check we have props...
    if(typeof this.props.size == "undefined") {
      throw new Error("Props size must be provided to spacer component.");
    }
  }

  public render() {
    return (
      <div style={{width: '100%', height: this.props.size}}>
      </div>
    );
  }

}