import React from 'react';
import SafeArea from '../../components/SafeArea/SafeArea';
import { Breadcrumb, IBreadcrumbItem, ICalloutProps, Icon, TooltipHost } from '@fluentui/react';
import { Card, CardHeader } from '@fluentui/react-card';
import { Button, Caption1, MessageBar, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, Text } from '@fluentui/react-components';
import Spacer from '../../components/Spacer/Spacer';
import { CircleHint24Regular, Delete12Regular, Delete20Filled, Delete20Regular, Delete24Regular } from '@fluentui/react-icons';
import BillingOverviewChart from '../../components/BillingOverviewChart/BillingOverviewChart';
import Billing_Method, { Billing_Method_Type } from '../../lib/Billing/BillingMethod';
import ViewLoader from '../../components/ViewLoader/ViewLoader';
import BillingAddCreditCard from '../../components/BillingAddCreditCard/BillingAddCreditCard';
import BillingConfirmMethodDeleteModal from '../../components/Billing/BillingConfirmMethodDeleteModal/BillingConfirmMethodDeleteModal';

import { CircleRegular } from '@fluentui/react-icons';

export default class BillingView extends React.Component {
  private breadcrumbItems: IBreadcrumbItem[];
  private styles: any;

  private _mounted: boolean = false;

  public constructor(props: any) {
    super(props);

    // define basic breadcrumb data...
    this.breadcrumbItems = [
      { text: 'Home', key: 'home', onClick: () => (this.props as any).navigate("/dashboard") },
      { text: 'Billing', key: 'billing', isCurrentItem: true },
    ];

    // define initial state..
    this.state = {
      billing_methods_loading: true,
      billing_methods: [],

      // default drawer state must be closed.
      display_add_method: false,

      // default state for confirm billing method deletion...
      confirm_method_deletion: false,
      delete_method_id: 0
    };

    // load the data...
    this.load_data();

    // define view styles...
    this.styles = {
      breadcrumb_wrapper: {
        marginLeft: -7
      },

      cards_wrapper: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        marginLeft: 1,

        gap: 25
      },

      card_wrapper: {
        // width: "50%"
      }
    };
  }

  public componentDidMount(): void {
    this._mounted = true;
  }

  public componentWillUnmount(): void {
    this._mounted = false;
  }

  public load_data = async () => {
    try {
      const billingMethods = await Billing_Method.get_billing_methods();
      this.setState({
        billing_methods: billingMethods,
        billing_methods_loading: false
      });

    }
    catch(error) {
      throw error;
    }
  }

  public on_add_billing_method_hide = async (billingMethodCreated: boolean) => {
    if(this._mounted) {
      this.setState({
        display_add_method: false,
      });

      // refresh the billing method data...
      if(billingMethodCreated) {
        this.setState({
          billing_methods_loading: true
        });
        await this.load_data();
      }
    }
  }

  public can_delete_billing_method(): boolean {
    const state = this.state as any;
    return (state.billing_methods.length > 0);
  }

  public delete_billing_method = async () => {
    try {


    }
    catch(error) {
      throw error;
    }
  }

  public render() {
    const state = this.state as any;

    return (
      <SafeArea>
        
        <BillingAddCreditCard display={state.display_add_method} onClose={this.on_add_billing_method_hide}/>
        <BillingConfirmMethodDeleteModal 
          display={state.confirm_method_deletion} 
          onCancel={() => {
            this.setState({confirm_method_deletion: false});
          }} 
          onConfirm={() => {

          }} 
        />

        <div style={this.styles.breadcrumb_wrapper}>
          <Breadcrumb
            items={this.breadcrumbItems}
            maxDisplayedItems={5}
            ariaLabel="Breadcrumb with static width"
            overflowAriaLabel="More links"
          />
        </div>

        <h2>Billing Management</h2>

        <Spacer size='25px' />

        <MessageBar
          intent='success'
        >
          Your account has account credits enabled and activated.

          <span style={{textAlign: "right", float: "right", paddingRight: 15}}>
            Remaining (<b>&pound;100.00</b>)
          </span>
        </MessageBar>
        <Spacer size='25px' />

        <div style={this.styles.cards_wrapper}>
          <div style={{width: 600}}>
            <Card>
              {state.billing_methods_loading? 
                <ViewLoader height="180px" marginLeft='0' message='Loading Billing Methods' />
              :
                <div>
                    <CardHeader
                    header={<Text weight="semibold">Attached Billing Methods</Text>}
                    description={
                      <Caption1></Caption1>
                    }
                    action={
                      <Button onClick={() => {
                        this.setState({
                          display_add_method: true
                        });
                      }}>Add Card</Button>
                    }
                  />

                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHeaderCell><b>Name on card</b></TableHeaderCell>
                        <TableHeaderCell><b>Card number</b></TableHeaderCell>
                        <TableHeaderCell><b>Expiry Date</b></TableHeaderCell>
                      </TableRow>
                    </TableHeader>

                    <TableBody>
                      {state.billing_methods.map((billingMethod: Billing_Method_Type, index: number) => 
                        <TableRow key={index}>
                          <TableCell>
                            {billingMethod.name_on_card}
                          </TableCell>
                          <TableCell>
                            **** **** **** {billingMethod.last_four_digits}
                          </TableCell>
                          <TableCell>
                            {billingMethod.expiration_date}
                          </TableCell>
                          <TableCell style={{width: "5%"}}>
                            <TooltipHost
                                content={"Your primary billing method cannot be deleted."}
                              >
                              <Button 
                                disabled={!this.can_delete_billing_method()} 
                                style={{float: "right"}}
                                onClick={() => {
                                  this.setState({confirm_method_deletion: true});
                                }}
                              >
                                <Delete20Regular />
                              </Button>
                            </TooltipHost>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              }

              <br />

            </Card>
          </div>
          <div style={{
              width: 500,
              alignItems: 'center',
              justifyContent: 'center'
          }}>
            <Card>
              <CardHeader
                header={<Text weight='semibold'>Billing &amp; Cost Breakdown</Text>}
              />

              {/* <BillingOverviewChart /> */}
              <Text style={{marginTop: 100}} align='center'>
                <CircleHint24Regular /> <br />
                You do not have any active services.
              </Text>
              <Spacer size="150px" />
            </Card>
          </div>
        </div>
      </SafeArea>
    );
  }

}