const rootState = {
  account: {
    first_name: "",
    last_name: "",
    email_address: "",
    organisation_name: "",
    phone_number: ""
  }
};

const rootReducer = (state = rootState, action: any) => {
  switch(action.type) {
    
    case "ACCOUNT_UPDATE":
      return { ...state, account: action.payload}

    default:
      return state;
  }
}

export default rootReducer;