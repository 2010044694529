import React from 'react';
import SafeArea from '../../components/SafeArea/SafeArea';
import BlockedFeature from '../../components/BlockedFeature/BlockedFeature';

export default class AIContentView extends React.Component {

  constructor(props: any) {
    super(props);
  }

  public render() {
    return (
      <SafeArea>
        <BlockedFeature />
      </SafeArea>
    );
  }

}