import React from 'react';

export class DomainDnsPanel extends React.Component {

  private styles: any = {};

  constructor(props: any) {
    super(props);

    this.styles = {
      panelItem: {
        width: "100%"
      }
    }
  }

  public render() {
    return (      
      <div style={this.styles.panelItem}>
        <h2>Domain DNS Records</h2>
      </div>
    );
  }

}