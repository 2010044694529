import React from 'react';
import { Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, ThemeSettingName } from '@fluentui/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@fluentui/react/lib/Theme';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';

import logo from './logo.svg';
import './App.css';

import LoginView from './views/Authentication/Login/LoginView';

import Header from './components/Header/Header';
import DashboardView from './views/Dashboard/DashboardView';
import Sidenav from './components/Sidenav/Sidenav';
import SMSDashboardView from './views/SMS/SMSDashboardView';
import IndexRedirector from './components/IndexRedirector/IndexRedirector';
import WebsitesView from './views/Websites/WebsitesView';
import AIContent from './views/AIContent/AIContentView';
import AIContentView from './views/AIContent/AIContentView';
import ManageDomainView from './views/Domains/ManageDomainView';
import DomainsView from './views/Domains/DomainsView';
import ErrorDialogProvider from './components/ErrorDialog/ErrorDialogProvider';

import store, { await_persistor_rehydration, persistor } from './redux/ReduxStore';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import Authentication_Service from './lib/Authentication/AuthenticationService';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import ProtectedAuthRoute from './components/ProtectedAuthRoute/ProtectedAuthRoute';
import FullscerenLoader from './components/FullscreenLoader/FullscreenLoader';
import FullscreenLoaderProvider from './components/FullscreenLoaderProvider/FullscreenLoaderProvider';
import Initial_Load from './lib/InitialLoad/InitialLoad';
import withNavigate from './components/WithNavigate/WithNavigate';
import WebsiteEditorView from './views/Websites/WebsiteEditorView';
import AccountView from './views/Account/AccountView';
import BillingView from './views/Billing/BillingView';
import HostingView from './views/Hosting/HostingView';
import Hosting from './lib/Hosting/Hosting';
import OpenAccountView from './views/Account/OpenAccount/OpenAccountView';
import SupportView from './views/Support/SupportView';

const boldStyle: Partial<ITextStyles> = { root: { fontWeight: FontWeights.semibold } };
const stackTokens: IStackTokens = { childrenGap: 15 };
const stackStyles: Partial<IStackStyles> = {
  root: {
    width: '960px',
    margin: '0 auto',
    textAlign: 'center',
    color: '#605e5c',
  },
};

const theme = {
  fonts: {
    medium: {
      fontSize: '16px',
      fontWeight: '400' // Adjust the font size as needed
    },
  },
};

export class App extends React.Component {

  public constructor(props: any) {
    super(props);

    // define state...
    this.state = {
      app_loading: true
    };
    
    // await for redux rehydration...
    await_persistor_rehydration.then(async () => {
      // do the initial authentication validation...
      const authenticated = Authentication_Service.authentication_sso_local_redirect();
      const reduxState = store.getState();
      if(reduxState.global_auth.is_authenticated) {
        // do the initial load stuff to cache data for the session...
        await Initial_Load.perform_initial_load();
        this.setState({
          app_loading: false
        });

        Authentication_Service.refresh_authentication_token_cycle();
      }
      else {
        const refreshTokenValidity = await Authentication_Service.verify_with_refresh_token();
        if(refreshTokenValidity) {
          // do the initial load stuff to cache data for the session...
          await Initial_Load.perform_initial_load();
          this.setState({
            app_loading: false
          });

          Authentication_Service.refresh_authentication_token_cycle();
        }
      }

      this.setState({
        app_loading: false
      });
    });

  }

  public componentDidMount(): void {
    // define basic app meta..
    document.title = "121 Console";

  }

  public render() {
    const state = this.state as any;
    
    const Views = {
      Dashboard: withNavigate(DashboardView),
      Websites: withNavigate(WebsitesView),
      WebsiteEditor: withNavigate(WebsiteEditorView),
      AccountView: withNavigate(AccountView),
      BillingView: withNavigate(BillingView),
      HostingView: withNavigate(HostingView),
      SupportView: withNavigate(SupportView)
    };

    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <FluentProvider theme={webLightTheme} >

            {state.app_loading? (
              <FullscerenLoader display={true} />

            ) : (
              <div>
                <FullscerenLoader display={state.app_loading} />

                <Header />
                <Sidenav />
      
                <ErrorDialogProvider />
      
                <Router>
                  <Routes>
                    <Route path='/' element={<IndexRedirector />} />
      
                    <Route path="/auth/login" element={
                      <ProtectedAuthRoute>
                        <LoginView />
                      </ProtectedAuthRoute>
                    }/>
                    
                    <Route path="/account" element={
                      <ProtectedRoute>
                        <Views.AccountView />
                      </ProtectedRoute>
                    }/>

                    <Route path="/dashboard" element={
                      <ProtectedRoute>
                        <Views.Dashboard />
                      </ProtectedRoute>
                    } />
      
                    <Route path='/websites' element={
                      <ProtectedRoute>
                        <Views.Websites />
                      </ProtectedRoute>
                    }/>

                    <Route path='/websites/:website_domain/editor' element={
                      <ProtectedRoute>
                        <Views.WebsiteEditor />
                      </ProtectedRoute>
                    }/>
      
                    <Route path="/sms" element={
                      <ProtectedRoute>
                        <SMSDashboardView />
                      </ProtectedRoute>
                    } />
      
                    <Route path='/ai' element={
                      <ProtectedRoute>
                        <AIContentView />
                      </ProtectedRoute>
                      }/>

                    <Route path='/billing' element={
                      <ProtectedRoute>
                        <Views.BillingView />
                      </ProtectedRoute>
                      }/>
      
                    <Route path='/domains' element={
                      <ProtectedRoute>
                        <DomainsView />
                      </ProtectedRoute>
                      }/>
                    <Route path='/domains/manage_domain' element={
                      <ProtectedRoute>
                        <ManageDomainView />
                      </ProtectedRoute>
                    }/>

                    <Route path='/support' element={
                      <ProtectedRoute>
                        <Views.SupportView />
                      </ProtectedRoute>
                      }/>
                          
                    <Route path='/hosting' element={
                      <ProtectedRoute>
                        <Views.HostingView />
                      </ProtectedRoute>
                      }/>
                  </Routes>
                </Router>
              </div>

            )}
            
          </FluentProvider>
        </PersistGate>
      </Provider>
    );
  }

}
