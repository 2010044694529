// keycloak.ts
import Keycloak from 'keycloak-js';

export default class Keycloak_Env {
  
  private instance: Keycloak | null = null;

  public constructor() {
    if(!this.instance) {
      this.instance = new Keycloak({
        url: 'https://sso.121domains.co.uk',
        realm: '121_digital_dev',
        clientId: '121_digital_console',
      });

    }
  }

  public get_instance(): Keycloak {
    if(typeof this.instance !== "undefined") {
      return this.instance as Keycloak;
    }
    else {
      throw new Error("Cannot get instance of undefined keycloak ENV.");
    }
  }

}