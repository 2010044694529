import React from 'react';
import SafeArea from '../../components/SafeArea/SafeArea';
import { Breadcrumb, CommandBar, IBreadcrumbItem } from '@fluentui/react';

export default class DomainsView extends React.Component {

  private breadcrumbItems: IBreadcrumbItem[];
  private styles: any = {};
  private commandBarItems: Array<any>;

  constructor(props: any) {
    super(props);

    // define basic breadcrumb data...
    this.breadcrumbItems = [
      { text: 'Home', key: 'home', onClick: () => window.location.assign("/dashboard") },
      { text: 'Domain Names', key: 'manage_domain', isCurrentItem: true },
    ];

    // define view styles...
    this.styles = {
      commandBar: {
        borderTopStyle: "solid",
        width: "100%"
      }
    };

    this.commandBarItems = [
      {
        key: 'newItem',
        name: 'New',
        iconProps: { iconName: 'Add' },
        onClick: () => alert('New item clicked')
      },
      {
        key: 'upload',
        name: 'Upload',
        iconProps: { iconName: 'Upload' },
        onClick: () => alert('Upload clicked')
      },
      {
        key: 'share',
        name: 'Share',
        iconProps: { iconName: 'Share' },
        onClick: () => alert('Share clicked')
      }
      // Add more commands as needed
    ];
  }

  public render() {
    return (
      <SafeArea>
        <div style={{width: "100%"}}>
          <Breadcrumb
            items={this.breadcrumbItems}
            maxDisplayedItems={5}
            ariaLabel="Breadcrumb with static width"
            overflowAriaLabel="More links"
          />
          <h1>Manage Domain Names</h1>

          <div style={this.styles.commandBar}>
            <CommandBar
              items={this.commandBarItems}
              ariaLabel="Use left and right arrow keys to navigate between commands"
            />
          </div>
        </div>
      </SafeArea>
    )
  }

}