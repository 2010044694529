import React from 'react';
import SafeArea from '../../components/SafeArea/SafeArea';
import { Breadcrumb, DefaultButton, IBreadcrumbItem, MessageBar, MessageBarType, PrimaryButton } from '@fluentui/react';
import { DomainHealthPanel } from '../../components/Domains/DomainHealthPanel';
import { DomainNameserversPanel } from '../../components/Domains/DomainNameserversPanel';
import { DomainDnsPanel } from '../../components/Domains/DomainDnsPanel';
import { DomainNameserverEditPanel } from '../../components/Domains/DomainNameserverEditPanel';
import Spacer from '../../components/Spacer/Spacer';
import { Button } from '@fluentui/react-components';

export default class ManageDomainView extends React.Component {

  private styles: any = {};
  private breadcrumbItems: IBreadcrumbItem[];

  constructor(props: any) {
    super(props);

    // define basic breadcrumb data...
    this.breadcrumbItems = [
      { text: 'Home', key: 'home', onClick: () => window.location.assign("/dashboard") },
      { text: 'Domain Names', key: 'domains', onClick: () => window.location.assign("/domains") },
      { text: 'Manage Domain', key: 'domain', onClick: () => window.location.assign("/domains") },
      { text: '121digital.co.uk', key: 'manage_domain', isCurrentItem: true },
    ];

    // define the views style data...
    this.styles = {
      panelWrapper: {
        display: "flex",
        columnGap: 25
      }
    };

    this.state = {
      editNameaervers: false,
      nameservers: [
        {nameserverRecordName: "Nameserver 1", value: "ns1.121domains.co.uk"},
        {nameserverRecordName: "Nameserver 2", value: "ns2.121domains.co.uk"},
        {nameserverRecordName: "Nameserver 3", value: "ns3.121domains.co.uk"},
        {nameserverRecordName: "Nameserver 4", value: "ns4.121domains.co.uk"}
      ]
    }

  }

  public render() {
    const panelCount = 3;
    const columnWidthPercentage = 100 / panelCount;

    const state = (this.state as any);

    return (
      <SafeArea>
        <Breadcrumb
          items={this.breadcrumbItems}
          maxDisplayedItems={5}
          ariaLabel="Breadcrumb with static width"
          overflowAriaLabel="More links"
        />

        <h1>Manage Domain Name (121digital.co.uk)</h1>

        <MessageBar
          messageBarType={MessageBarType.warning}
          isMultiline={false}
        >
          This domain name seems to be using external nameservers. This prevents
          you from making changes to your domains DNS records through 121 Digital Console.
        </MessageBar>

        <Spacer size="25px" />
      
        <div style={this.styles.panelWrapper}>
          <div style={{width: columnWidthPercentage + "%"}}>
            <DomainHealthPanel />
          </div>
          <div style={{width: columnWidthPercentage + "%"}}>
            <DomainNameserversPanel nameservers={state.nameservers} />
            <DomainNameserverEditPanel
              nameservers={state.nameservers}
              isOpen={state.editNameservers}
              onDismiss={() => this.setState({editNameservers: false})}
            />
            <Spacer size='15px' />

            <PrimaryButton onClick={(() => {
              this.setState({editNameservers: true})
            })}>Update Nameservers</PrimaryButton>
            
            <DefaultButton style={{marginLeft: 5}}>Reset to default</DefaultButton>
          </div>
        </div>

        <Spacer size="25px" />
        <DomainDnsPanel />

      </SafeArea>
    );
  }

}