import React from 'react';
import { 
  Button,
  Spinner, 
  ButtonProps, 
  Dialog, 
  DialogSurface, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Field, 
  Input } from "@fluentui/react-components";

export interface Error_Modal_Props {
  errorTitle: string,
  errorBody: string,
  display: boolean,

  onClose?: any
}

export default class ErrorDialog extends React.Component <Error_Modal_Props> {

  public constructor(props: any) {
    super(props);

    this.state = {
      error_title: props.errorTitle,
      error_body: props.errorBody,
      display: props.display
    };
  }

  public on_dialog_close = (): void => {
    this.setState({
      display: false
    });

    if(typeof this.props.onClose !== 'undefined') {
      this.props.onClose();
    }
  }

  public render() {
    const state = this.state as any;

    return (
      <Dialog open={state.display}>
        <DialogSurface>
          <DialogTitle>{state.error_title}</DialogTitle>
          <DialogContent>
            <p>
              {state.error_body}
            </p>
            <br />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.on_dialog_close}>Close</Button>
          </DialogActions>
        </DialogSurface>
      </Dialog>
    );
  }

}