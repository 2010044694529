const rootState = {
  capabilities: []
};

const rootReducer = (state = rootState, action: any) => {
  switch(action.type) {
    
    case "CAPABILITIES_UPDATE":
      return { ...state, capabilities: action.payload}

    default:
      return state;
  }
}

export default rootReducer;