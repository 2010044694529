import React from 'react';
import SafeArea from '../../components/SafeArea/SafeArea';
import Hosting from '../../lib/Hosting/Hosting';
import ViewLoader from '../../components/ViewLoader/ViewLoader';
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react';
import Spacer from '../../components/Spacer/Spacer';
import { Card, CardHeader } from '@fluentui/react-card';
import { Button, Caption1, Text } from '@fluentui/react-components';
import CPanelHostingCard from '../../components/Hosting/CPanelHostingCard/CPanelHostingCard';
import DeployHostingServiceDrawer from '../../components/Hosting/DeployHostingServiceDrawer/DeployHostingServiceDrawer';

export default class HostingView extends React.Component {

  private styles: any = {};
  private breadcrumbItems: IBreadcrumbItem[];

  public constructor(props: any) {
    super(props);

    // define breadcrumb items...
    this.breadcrumbItems = [
      { text: 'Home', key: 'home', onClick: () => (this.props as any).navigate("/dashboard") },
      { text: 'Your Account', key: 'manage_account', isCurrentItem: true },
    ];

    // define initial state...
    this.state = {
      data_loading: true,
      hosting_services: [],

      deploy_hosting_drawer_open: false
    };
    this.load_data();

    // define the view styles..
    this.styles = {
      breadcrumb_wrapper: {
        marginLeft: -7
      },

      services_wrapper: {
        display: "flex",
        flexDirection: "row",
        gap: 15,
        marginLeft: 1
      },

      service_card: {
        width: 400
      }
    };
  }

  public load_data = async () => {
    try {
      const hostingServices = await Hosting.get_hosting_services();
      this.setState({
        data_loading: false,
        hosting_services: hostingServices
      });

    }
    catch(error) {
      throw error;
    }
  }

  public render() {
    const state = this.state as any;
    return (
      <SafeArea>
        <DeployHostingServiceDrawer 
          display={state.deploy_hosting_drawer_open}
          onCancel={() => {
            this.setState({
              deploy_hosting_drawer_open: false
            });
          }} 
          onComplete={() => {}}
        />

        {state.data_loading? (
          <ViewLoader message='Loading your hosting services' />

        ): (
          <div>
            <div style={this.styles.breadcrumb_wrapper}>
              <Breadcrumb
                items={this.breadcrumbItems}
                maxDisplayedItems={5}
                ariaLabel="Breadcrumb with static width"
                overflowAriaLabel="More links"
              />
            </div>
            <h2>Website Hosting Services</h2>

            <Spacer size="25px" />

            <div style={this.styles.services_wrapper}>
              {state.hosting_services.map((hostingService: any, index: number) => (
                <div style={this.styles.service_card}>
                  <CPanelHostingCard
                    primary_domain={hostingService.primary_domain}
                    service_id={hostingService.order_id}
                    hosting_zone='Northern Europe ( Dublin, Ireland 🇮🇪 )'
                  />
                </div>
              ))}
            </div>
          </div>
        )}

      </SafeArea>
    );
  }

}