import { CommandBar } from '@fluentui/react';
import {
  IContextualMenuItemProps,
  ContextualMenuItem,
  IContextualMenuItemStyles,
  IContextualMenuStyles,
} from '@fluentui/react/lib/ContextualMenu';
import React from 'react';
import WebsiteEditorGlobalSettingsDrawer from '../GlobalSettingsDrawer/WebsiteEditorGlobalSettingsDrawer';
import websiteEditorStore from '../../../redux/WebsiteEditor/WebsiteEditorRedux';

const CustomMenuItem: React.FunctionComponent<IContextualMenuItemProps> = props => {
  // Due to ContextualMenu implementation quirks, passing styles or onClick here doesn't work.
  // The onClick handler must be on the ICommandBarItemProps item instead (_overflowItems in this example).
  return <ContextualMenuItem {...props} />;
};

export interface WebsiteEditorCommandBar_Props {
  site_data: {}
};

export default class WebsiteEditorCommandBar extends React.Component {

  private commandBarItems: any = [];
  private styles: any;

  private _is_mounted = false;

  public constructor(props: any) {
    super(props);

    this.state = {
      display_global_settings: false
    };

    const reduxState = websiteEditorStore.getState();

    this.commandBarItems = [
      {
        key: 'newItem',
        name: 'Create',
        iconProps: { iconName: 'Add' },
        subMenuProps: {
          // Must specify the menu item type for submenus too!
          contextualMenuItemAs: CustomMenuItem,
          // Styles are passed through to menu items here
          items: [
            { key: 'create_blog_post', text: 'Create Blog Post', iconProps: { iconName: 'Edit' } },
            { key: 'create_page', text: 'Create Page', iconProps: { iconName: 'Edit' } },
          ],
        },
        onClick: () => alert('New item clicked')
      },
      {
        key: 'settings',
        name: 'Website Settings',
        iconProps: { iconName: 'Settings' },
        onClick: () => {
          this.setState({
            display_global_settings: true
          });
        }
      },
      {
        key: 'share',
        name: 'Visit Website',
        iconProps: { iconName: 'Share' },
        onClick: () => {
          window.open(reduxState.site_data.basic_data.domain_name, "_blank");
        }
      }
      // Add more commands as needed
    ];

    this.styles = {
      commandBar: {
        // borderTopStyle: "solid",
        // borderTopWidth: 0.25,
        // borderBottomStyle: "solid",
        // borderBottomWidth: 0.25,
        borderStyle: 'solid',
        borderWidth: 0.25,

        width: "100%",
        marginTop: 25
      }
    };
  }

  public componentDidMount(): void {
    this._is_mounted = true;
  }

  public componentWillUnmount(): void {
    this._is_mounted = false;
  }

  public render() {
    const state = this.state as any;

    return (
      <div>
        <WebsiteEditorGlobalSettingsDrawer 
          display={state.display_global_settings} 
          onClose={() => this.setState({display_global_settings: false})}
        />

        <div style={this.styles.commandBar}>
          <CommandBar
          style={{marginLeft: -25}}
            items={this.commandBarItems}
            ariaLabel="Use left and right arrow keys to navigate between commands"
          />
        </div>
      </div>
    );
  }

}