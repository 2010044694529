const rootState = {
  global_auth: {
    is_authenticated: false,
    is_refreshing: false,
    auth_data: {
      "access_token": "",
      "access_token_lifespan": 0,
      "access_token_last_updated": new Date(),
      "refresh_token_lifespan": 0,
      "refresh_token_last_updated": new Date(),
      "refresh_token": "",
      "token_type": "Bearer",
    }    
  },
  account_data: {

  },
  global_error: {
    error_title: "",
    error_body: "",
    display: false
  }
};

const rootReducer = (state = rootState, action: any) => {
  switch(action.type) {
    
    case "GLOBAL_ERROR_UPDATE":
      return { ...state, global_error: action.payload}

    case "AUTH_UPDATE":
      return { ...state, global_auth: action.payload}

    case "REFRESH_UPDATE":
      return { ...state, is_refreshing: action.payload}

    default:
      return state;
  }
}

export default rootReducer;