import { PrimaryButton, Link, Stack, StackItem, Modal, DefaultButton } from '@fluentui/react';
import { Button } from '@fluentui/react-components';
import React from 'react';

import Authentication_Service from './../../lib/Authentication/AuthenticationService';
import Global_Error from '../../lib/GlobalError/GlobalError';

export interface Blocked_Feature_Props {
  logoutButton?: boolean
}

export default class BlockedFeature extends React.Component <Blocked_Feature_Props> {

  private styles: any = {};

  constructor(props: any) {
    super(props);

    this.styles = {
      modalContainer: {
        width: 500,
        padding: 25,
        paddingTop: 0
      }
    }

    let displayLogoutButton = false;
    if(this.props.logoutButton) {
      displayLogoutButton = true;
    }

    this.state = {
      displayInfoModal: false,
      displayLogoutButton: displayLogoutButton
    }

  }

  public returnToDashboard() {
    window.location.assign("/dashboard");
  }

  public logout_button_click = async (): Promise<void> => {
    const authLogout = await Authentication_Service.destroy_session_redirect();
    if(!authLogout) {
      // there was an issue loggoign the user out...
      const globalError = new Global_Error()
      globalError.display_user_visible_error({
        error_title: "Unexpected Error",
        error_content: "There was an issue logging you out of your account, please try again or contact support@121digital.co.uk"
      });

      globalError.dialog_events.on("dialog_close", () => {
        globalError.dialog_events.removeAllListeners();
      });
    }
  }

  public render() {
    const state = this.state as any;

    return (
      <div>

        <Stack tokens={{childrenGap: 25}}>
          <StackItem>
            <h2>Not available.</h2>
            <p>This service is not available to your account at the moment.</p>
            <Link onClick={() => this.setState({displayInfoModal: true})}>
              Why am I seeing this message?
            </Link>
          </StackItem>

          <StackItem>
            <PrimaryButton
              iconProps={{ iconName: 'Home' }}
              onClick={this.returnToDashboard}
            >Dashboard Home</PrimaryButton>

            {state.displayLogoutButton? (
              <DefaultButton style={{marginLeft: 15, marginTop: -10}}
              onClick={this.logout_button_click}
              >Logout</DefaultButton>
            ) :(null)}
          </StackItem>
        </Stack>

        <Modal
            isOpen={(this.state as any).displayInfoModal}
            onDismiss={() => this.setState({displayInfoModal: false})}
            isBlocking={false}
            containerClassName="modalContainer"
        > 

            <div style={this.styles.modalContainer}>
                <h2>Why this may not be available.</h2>
                <span>
                  The requested feature may not be available to your account for a number of reasons.
                  Your organisation may have restricted access, or your account has not been configured
                  to allow access to this feature.
                </span>

                <br /><br />

                <span>Contact 121 Digital support for more information.</span>

                <br />


                <PrimaryButton text='Ok' onClick={
                  () => this.setState({displayInfoModal: false})
                }/>
                <DefaultButton style={{marginTop: 25, marginLeft: 10}} text="Close" onClick={
                  () => this.setState({displayInfoModal: false})
                } />
            </div>
        </Modal>
      </div>
    );
  }

}