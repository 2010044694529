import React from 'react';
import SafeArea from '../../components/SafeArea/SafeArea';
import { Panel, Text, Button, PrimaryButton } from '@fluentui/react';
import { Card, CardHeader, CardPreview, CardFooter } from '@fluentui/react-components';
import { mergeStyles, mergeStyleSets } from '@fluentui/react/lib/Styling'
import Spacer from '../../components/Spacer/Spacer';
import withNavigate from '../../components/WithNavigate/WithNavigate';
import AccountCard from '../../components/AccountCard/AccountCard';
import BillingCard from '../../components/BillingCard/BillingCard';
import SupportCard from '../../components/SupportCard/SupportCard';
import { Capabilities, Capability_Types } from '../../lib/Capabilities/Capabilities';

export default class DashboardView extends React.Component {

  private styles: any = {};

  constructor(props: any) {
    super(props);
    
    this.styles = {
      main: {
        gap: "36px",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        paddingLeft: 1,
      },
    
      card: {
        width: "360px",
        maxWidth: "100%",
        height: "fit-content",
        borderWidth: 0.5,
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        borderRadius: 8,
        borderStyle: "none"
      },
    
      section: {
        width: "fit-content",
      },
    
      title: { margin: "0 0 12px" },
    
      horizontalCardImage: {
        width: "64px",
        height: "64px",
      },
    
      headerImage: {
        borderRadius: "4px",
        maxWidth: "44px",
        maxHeight: "44px",
      },

      account_overview_wrapper: {
        width: "33%"
      }
    }
  }

  public get_dashboard_widgets = (): any => {
    try {
      const styles = this.styles;
      const cardStyles = mergeStyleSets(this.styles) as any;

      // Define base capabilities array...
      let capabilities: Array<any> = [];

      // Do we have a websites capability?
      if (Capabilities.has_capability(Capability_Types.websites)) {
        capabilities.push({
          id: 1, // Adding a unique ID
          header: "Websites",
          description: "Your Websites",
          redirect_url: "/websites"
        });
      }

      // Do we have a domains capability?
      if (Capabilities.has_capability(Capability_Types.domains)) {
        capabilities.push({
          id: 1, // Adding a unique ID
          header: "Domains",
          description: "Your Domains",
          redirect_url: "/domains"
        });
      }

      // Do we have a partner capability?
      if (Capabilities.has_capability(Capability_Types.partner)) {
        capabilities.push({
          id: 1, // Adding a unique ID
          header: "Partner",
          description: "Partner Center",
          redirect_url: "/partner"
        });
      }

      // Do we have the account managment capability?
      if (Capabilities.has_capability(Capability_Types.account)) {
        capabilities.push({
          id: 1, // Adding a unique ID
          header: "Account",
          description: "Your Account",
          redirect_url: "/account"
        });
      }

      // Do we have the account managment capability?
      if (Capabilities.has_capability(Capability_Types.hosting)) {
        capabilities.push({
          id: 1, // Adding a unique ID
          header: "Hosting",
          description: "Your Hosting",
          redirect_url: "/hosting"
        });
      }

      // Do we have the billing managment capability?
      if (Capabilities.has_capability(Capability_Types.billing)) {
        capabilities.push({
          id: 1, // Adding a unique ID
          header: "Billing",
          description: "Your Billing",
          redirect_url: "/billing"
        });
      }


      return capabilities.map((capability: any, index: any) => (
        <Card key={capability.id || index} className={cardStyles.card} orientation="horizontal">
          <CardPreview className={styles.horizontalCardImage}>
            {/* <img
              className={styles.horizontalCardImage}
              src={resolveAsset("app_logo.svg")}
              alt="App Name Document"
            /> */}
          </CardPreview>

          <CardHeader
            header={<Text>{capability.header}</Text>}
            description={
              // <Caption1 className={styles.caption}>Developer</Caption1>
              <b>{capability.description}</b>
            }
            action={
              <PrimaryButton
                aria-label="More options"
                label='Manage'
                onClick={() => {
                  (this.props as any).navigate(capability.redirect_url);
                }}
              >
                Manage
              </PrimaryButton>
            }
          />
        </Card>
      ));
    } catch (error) {
      throw error;
    }
  }

  public render() {
    const styles = this.styles;
    const cardStyles = mergeStyleSets(this.styles) as any;
      
    return (
      <SafeArea>
        <Spacer size="25px" />
        <h1>Featured Services</h1>

        <div style={this.styles.main}>
          {this.get_dashboard_widgets()}
        </div>
        
        <Spacer size='125px' />

        <div style={{marginLeft: 2.5, display: "flex", gap: 50}}>
          <div style={this.styles.account_overview_wrapper}>
            {/* <h2>Account Overview</h2> */}
            <AccountCard 
              onActionButtonClick={() => {
                (this.props as any).navigate("/account");
              }}
            />
          </div>
          <div style={this.styles.account_overview_wrapper}>
            {/* <h2>Billing Overview</h2> */}
            <BillingCard />
          </div>
          <div style={this.styles.account_overview_wrapper}>
            {/* <h2>Support Overview</h2> */}
            <SupportCard />
          </div>
        </div>

      </SafeArea>
    );
  }
}