import React from 'react';
import {
  makeStyles,
  Button,
  Caption1,
  Text,
  tokens,
  Subtitle1,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHeader,
  Link,
  TableHeaderCell,
} from "@fluentui/react-components";
import { MoreHorizontal20Regular } from "@fluentui/react-icons";
import { Card, CardHeader, CardPreview } from "@fluentui/react-components";
import ElementorEmbedDialog from '../ElementorEmbedDialog/ElementorEmbedDialog,';

export interface WebsiteEditorPagesCard_Props {
  pages: any,
  onPageEditorSelect?: any
};

export default class WebsiteEditorPagesCard extends React.Component <WebsiteEditorPagesCard_Props> {

  public constructor(props: any) {
    super(props);

    this.state = {
      display_elementor_dialog: false,
      editor_page: {
        page_title: "",
        page_id: 0
      }
    }

    console.log(this.props.pages);
  }

  // private on_edit_page_select = () => {
  //   if(typeof this.props.onPageEditorSelect !== 'undefined') {
  //     this.props.onPageEditorSelect(0);
  //   }
  // }

  public display_elementor_editor_dialog = (pageId: number, pageTitle: number): void => {
    this.setState({
      display_elementor_dialog: true,
      editor_page: {
        page_title: pageTitle,
        page_id: pageId
      }
    });
  }

  public render() {
    const state = this.state as any;
  
    return (
      <div>
        <ElementorEmbedDialog 
          url={"https://121digital.co.uk"}
          page={state.editor_page}
          display={state.display_elementor_dialog}
          pageId={state.editor_page.page_id}
          onClose={() => {
            this.setState({
              display_elementor_dialog: false
            })
          }}
        />

        <section>
          <Card>
            <CardHeader
              header={<Text weight="semibold">Website Pages</Text>}
              description={
                <Caption1></Caption1>
              }
              action={
                <Button
                  appearance="transparent"
                  icon={<MoreHorizontal20Regular />}
                  aria-label="More options"
                  onClick={() => {
                  
                  }}
                />
              }
            />

            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Title</TableHeaderCell>
                  <TableHeaderCell>URL</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {this.props.pages.map((page: any, index: any) => (
                  <TableRow key={index}>
                    <TableCell>{page.title}</TableCell>
                    <TableCell>/{page.slug}</TableCell>
                    <TableCell align='right'>
                      <Button 
                        onClick={() => {
                          this.display_elementor_editor_dialog(page.ID, page.title);
                        }}
                        style={{float: "right"}}>Editor</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <br />

            <Button  
              style={{width: 150}}
              onClick={() => {
        
              }}
            >All Pages</Button>
          </Card>
        </section>
      </div>
    );
  }

}