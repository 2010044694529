import React from 'react';
import SafeArea from '../../components/SafeArea/SafeArea';
import { Breadcrumb, CommandBar, IBreadcrumbItem, Stack, StackItem, ThemeSettingName } from '@fluentui/react';
import WebsiteCard, { WebsiteCard_Website } from '../../components/WebsiteCard/WebsiteCard';
import {WebsiteCard_Props} from '../../components/WebsiteCard/WebsiteCard';
import Backend_Request from '../../lib/Request/BackendRequest';
import { Spinner } from '@fluentui/react-components';
import ViewLoader from '../../components/ViewLoader/ViewLoader';
import Global_Error from '../../lib/GlobalError/GlobalError';

export default class WebsitesView extends React.Component {

  private commandBarItems: Array<any>;
  private breadcrumbItems: IBreadcrumbItem[];
  private styles: any;

  private _is_mounted: boolean = false;
  

  constructor(props: any) {
    super(props);

    // define basic breadcrumb data...
    this.breadcrumbItems = [
      { text: 'Home', key: 'home', onClick: () => (this.props as any).navigate("/dashboard") },
      { text: 'Websites', key: 'manage_websites', isCurrentItem: true },
    ];

    this.commandBarItems = [
      {
        key: 'newItem',
        name: 'New',
        iconProps: { iconName: 'Add' },
        onClick: () => alert('New item clicked')
      },
      {
        key: 'upload',
        name: 'Upload',
        iconProps: { iconName: 'Upload' },
        onClick: () => alert('Upload clicked')
      },
      {
        key: 'share',
        name: 'Share',
        iconProps: { iconName: 'Share' },
        onClick: () => alert('Share clicked')
      }
      // Add more commands as needed
    ];

    this.state = {
      website_order_count: 0,
      websites: [],

      data_loading: true,
      data_loading_type: "websites"
    };

    // define view styles...
    this.styles = {
      commandBar: {
        borderTopStyle: "solid",
        width: "100%"
      },
      breadcrumb_wrapper: {
        marginLeft: -5
      },

      card_wrapper: {
        display: "flex",
        marginLeft: 2.5,
        width: "100%"
      },
      website_card_wrapper: {
        width: "100%",
        display: "flex",
        gap: 25
      }
    };

    this.load_data();
  }

  public componentDidMount(): void {
    this._is_mounted = true;
  }

  public componentWillUnmount(): void {
    this._is_mounted = false;
  }

  public load_data = async () => {
    try {

      // load the websites order meta from the backend...
      const request = new Backend_Request({
        endpoint: "v2/websites/get/meta",
        requestBody: {}
      });
      const result = await request.perform();
      if(result.is_success) {
        this.setState({
          website_order_count: result.response_data.websites_meta.websites_count
        });
      }

      // load the websites data from the backend...
      const websitesRequest = new Backend_Request({
        endpoint: "v2/websites/get",
        requestBody: {}
      });
      const websitesRequestResponse = await websitesRequest.perform();
      if(websitesRequestResponse.is_success) {
        
        let websites: Array<WebsiteCard_Website> = [];
        const websitesResponse = websitesRequestResponse.response_data.websites;
        for(let website of websitesResponse) {
          console.log(website);
          websites.push({
            site_title: website.website_meta.site_title,
            site_subtitle: website.website_meta.site_subtitle,
            date_created: website.date_created,
            website_url: website.website_url,
            site_id: website.website_id
          } as WebsiteCard_Website);
        }

        this.setState({
          websites: websites,
          data_loading: false
        });
      }

    }
    catch(error) {
      throw error;
    }
  }

  public access_website_editor = async (siteId: number): Promise<void> => {
    try {
      this.setState({
        data_loading: true,
        data_loading_type: "website_editor"
      });


      // // load the websites order meta from the backend...
      // const request = new Backend_Request({
      //   endpoint: "v2/websites/access_editor",
      //   requestBody: {
      //     website_id: siteId
      //   },
      //   verbose: true
      // });
      // const result = await request.perform();

      // if(result.is_success) {
      //   const responseData = result.response_data;
      //   if(responseData.can_access) {
      //     (window as any).open(responseData.redirect_url, '_blank').focus();

      //   }
      //   else {
      //     const globalError = new Global_Error()
      //     globalError.display_user_visible_error({
      //       error_title: "Cannot Access Site",
      //       error_content: "There seems to be an issue connecting to your website, please refresh the page and try again or contact support@121digital.co.uk"
      //     });
    
      //     globalError.dialog_events.on("dialog_close", () => {
      //       globalError.dialog_events.removeAllListeners();
      //     });
      //   }

      //   // this.setState({
      //   //   website_order_count: result.response_data.websites_meta.websites_count
      //   // });
      // }
      // else {
      //   const globalError = new Global_Error()
      //   globalError.display_user_visible_error({
      //     error_title: "Cannot Access Site",
      //     error_content: "There seems to be an issue connecting to your website, please refresh the page and try again or contact support@121digital.co.uk"
      //   });
  
      //   globalError.dialog_events.on("dialog_close", () => {
      //     globalError.dialog_events.removeAllListeners();
      //   });
      // }

      // load the websites order meta from the backend...
      // const request = new Backend_Request({
      //   endpoint: "v2/websites/pages/get",
      //   requestBody: {
      //     website_id: siteId,
      //   },
      //   verbose: true
      // });
      // const result = await request.perform();

      // if(result.is_success) {
      //   const responseData = result.response_data;
      //   console.log(responseData);
      // }

      // // change the loading state...
      // this.setState({
      //   data_loading: false,
      //   data_loading_type: "websites"
      // });

      const siteEncoded = {
        site_id: siteId
      };
      const encodedUrlParam = btoa(JSON.stringify(siteEncoded));
      (this.props as any).navigate(`/websites/${encodedUrlParam}/editor`);
    }
    catch(error) {
      throw error;
    }
  }
    
  private get_loading_type = (): any => {
    const state = this.state as any;
    if(state.data_loading_type == "websites") {
      return (
        <ViewLoader message='Loading your websites' />
      );
    }
    else if(state.data_loading_type == "website_editor") {
      return (
        <ViewLoader message='Loading website editor' />
      );
    }
  }

  public render() {
    const state = this.state as any;

    return (
      <SafeArea>

        {state.data_loading? (
          <this.get_loading_type />
        ) : (
          <div style={{width: "100%"}}>
            <div style={this.styles.breadcrumb_wrapper}>
              <Breadcrumb
                items={this.breadcrumbItems}
                maxDisplayedItems={5}
                ariaLabel="Breadcrumb with static width"
                overflowAriaLabel="More links"
              />
            </div>
            <h1>Manage Websites</h1>

            <div style={this.styles.commandBar}>

            </div>

            <br />

            <div style={this.styles.card_wrapper}>
              <div style={this.styles.website_card_wrapper}>
                {state.websites.map((website: any, index: any) => (
                  <div key={index} style={{width: "33%"}}>
                    <WebsiteCard onEditorClick={this.access_website_editor} website={website} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </SafeArea>
    );
  }

}