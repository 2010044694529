import { DefaultButton, DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from '@fluentui/react';
import React from 'react';

export class DomainHealthPanel extends React.Component {

  private styles: any = {};
  private columns: IColumn[];

  constructor(props: any) {
    super(props);

    this.styles = {
      panelItem: {
        width: "100%",
      }
    }

    this.state = {
      domainHealthItems: [
        { healthMetric: 'Expiry Date', value: "01/01/2024"},
        { healthMetric: 'Nameservers Setting', value: "External"},
        { healthMetric: 'Auto-Renew Status', value: "Enabled"},
        { healthMetric: 'Auto-Renew Date', value: "01/01/2024"},
      ]
    }

    this.columns = [
      {key: "health_metric", name: "Health Metric", minWidth: 50},
      {key: "health_metric", name: "Health Metric", minWidth: 50}
    ];
  }

  public render() {
    const state = (this.state as any);

    return (      
      <div style={this.styles.panelItem}>
        <h2>Domain Health</h2>

        <DetailsList
          items={state.domainHealthItems}
          // columns={this.columns}
          isHeaderVisible={false}
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.fixedColumns}
        />
      </div>
    );
  }

}