import { Button, Drawer, DrawerBody, DrawerHeader, DrawerHeaderTitle, Dropdown, Field, Input, Label, Link, Option, OptionGroup, Table, TableCell, TableRow, Text } from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import React from 'react';
import Spacer from '../../Spacer/Spacer';
import { Toggle } from '@fluentui/react';

export interface DeployHostingServiceDrawer_Props {
  display: boolean,
  onComplete: any,
  onCancel: any
};

export default class DeployHostingServiceDrawer extends React.Component <DeployHostingServiceDrawer_Props> {

  public constructor(props: any) {
    super(props);
  }

  public on_cancel = () => {
    this.on_dialog_close();
  }

  public on_dialog_close = () => {
    this.props.onCancel();
  }

  public render() {
    return (
      <Drawer
        position='start'
        size='medium'
        // type={}
        separator
        open={this.props.display}
        // onOpenChange={(_, { open }) => {})}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => {
                  this.on_dialog_close();
                }}
              />
            }
          >
            Deploy Website Hosting Service
          </DrawerHeaderTitle>
        </DrawerHeader>

        <DrawerBody>
          
          <Spacer size='25px' />
          <Text weight='bold'>Domain &amp; Website Settings</Text>
          <Spacer size='15px' />

          <Field
            label={"Hosting Service Name"}
          >
            <Input placeholder='Example Hosting Service' />
          </Field>

          <Spacer size={"15px"} />

          <Toggle label="Generate non-routable primary domain name" onText="On" offText="Off" />
          <Field label={"Primary Domain Name"}>
            <Input placeholder='example.com' />
          </Field>


          <Spacer size='25px' />
          <Text weight='bold'>Hosing Service Package</Text>
          <Spacer size='15px' />

          <Field
            label={"Hosting Service Package Type"}
          >
            <Dropdown
              placeholder="Select a hosting service type"
            >
              <OptionGroup label="Single Website Services">
                <Option key={"single_website"}>
                  Single Website (HOST_SWEB_01)
                </Option>
              </OptionGroup>
              <OptionGroup label="Multi Website Services">
                <Option key={"host_mweb_01"}>
                  Multi Domains (HOST_MWEB_01)
                </Option>
              </OptionGroup>
            </Dropdown>
            <Link>Learn more about hosting packages</Link>
          </Field>

          <Spacer size={"15px"} />

          <Field
            label={"Hosting Service Backend"}
          >
            <Dropdown
              placeholder='Select hosting service backend'
            >
              <Option value='cpanel_ubuntu' key="cpanel">
                cPanel Control Panel (Ubuntu Linux Server LTS)
              </Option>
            </Dropdown>
          </Field>

          <Spacer size={"15px"} />
          <Table>
            <TableRow>
              <TableCell><b>Domain Names</b></TableCell>
              <TableCell style={{textAlign: "right"}}>Unlimited</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Sub Domains</b></TableCell>
              <TableCell style={{textAlign: "right"}}>Unlimited</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Addon Domains</b></TableCell>
              <TableCell style={{textAlign: "right"}}>Unlimited</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Email Mailboxes</b></TableCell>
              <TableCell style={{textAlign: "right"}}>Unlimited</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Email Alias</b></TableCell>
              <TableCell style={{textAlign: "right"}}>Unlimited</TableCell>
            </TableRow>
          </Table>

          <Spacer size={"15px"} />
          <Text>
            When you confirm the deployment, a billing confirmation will be displayed.
            for pricing transparency and security.
          </Text>

          <Spacer size='55px' />
          <Button appearance='primary'>Confirm Deployment</Button>
          <Button onClick={this.on_cancel} style={{marginLeft: 15}}>Cancel</Button>

        </DrawerBody>
      </Drawer>
    );
  }

}