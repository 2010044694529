import React from 'react';

export default class IndexRedirector extends React.Component {

  constructor(props: any) {
    super(props);

    window.location.assign("/dashboard");
  }

  public render() {
    return (<div />);
  }

}