import React from 'react';
import {
  makeStyles,
  Button,
  Caption1,
  Text,
  tokens,
  Subtitle1,
} from "@fluentui/react-components";
import { MoreHorizontal20Regular } from "@fluentui/react-icons";
import { Card, CardHeader, CardPreview } from "@fluentui/react-components";

export interface WebsiteCard_Website {
  site_id: number,
  site_title: string,
  site_subtitle: string,
  date_created: string,
  website_url: string
};

export interface WebsiteCard_Props {
  website: WebsiteCard_Website,
  onEditorClick: any
};

export default class WebsiteCard extends React.Component <WebsiteCard_Props> {
  
  constructor(props: any) {
    super(props);
  }

  public render() {
    return (
      <div>
        <section>
          <Card>
            <CardHeader
              header={<Text weight="semibold">{this.props.website.site_title}</Text>}
              description={
                <Caption1>{this.props.website.website_url}</Caption1>
              }
              action={
                <Button
                  appearance="transparent"
                  icon={<MoreHorizontal20Regular />}
                  aria-label="More options"
                  onClick={() => {
                   
                  }}
                />
              }
            />
  
            <p>
              <i>Date Created: {this.props.website.date_created}</i> <br />
              {this.props.website.site_subtitle}
            </p>

            <Button 
              appearance='primary' 
              style={{width: 150}}
              onClick={() => {
                this.props.onEditorClick(this.props.website.site_id)
              }}
            >Website Editor</Button>
          </Card>
        </section>
      </div>
    );
  }

}