import { Card, CardHeader } from '@fluentui/react-card';
import { Button, Caption1, Spinner, Text } from '@fluentui/react-components';
import React from 'react';
import Spacer from '../../Spacer/Spacer';
import Hosting from '../../../lib/Hosting/Hosting';
import Global_Error from '../../../lib/GlobalError/GlobalError';

export interface CPanelHostingCard_Props {
  service_id: number,
  primary_domain: string,
  hosting_zone: string
}

export default class CPanelHostingCard extends React.Component <CPanelHostingCard_Props> {

  public constructor(props: any) {
    super(props);

    // set initial state...
    this.state = {
      is_redirecting: false
    }
  }

  public cpanel_auth_redirect = async () => {
    this.setState({
      is_redirecting: true
    });

    const cpanelHostingRedirect = await Hosting.get_cpanel_session_redirect_url(this.props.service_id);
    console.log(cpanelHostingRedirect);

    if(cpanelHostingRedirect.can_redirect) {
      window.open(cpanelHostingRedirect.redirect_url);

    }
    else {
      const globalError = new Global_Error()
        globalError.display_user_visible_error({
          error_title: "Could not redirect you to cPanel",
          error_content: cpanelHostingRedirect.reason
        });
  
        globalError.dialog_events.on("dialog_close", () => {
          globalError.dialog_events.removeAllListeners();
          this.setState({
            is_submitting: false
          });
        });
    }

    this.setState({
      is_redirecting: false
    });
  }

  public render() {
    const state = this.state as any;
    return (
      <Card>
        <CardHeader
          header={<Text weight="semibold">{this.props.primary_domain}</Text>}
          description={
            <Caption1></Caption1>
          }
        />
        <span>Service Type: cPanel Hosting</span>
        <span>Hosting Zone: {this.props.hosting_zone}</span>

        <Spacer size="15px" />
        <Button onClick={this.cpanel_auth_redirect} style={{width: 150}} appearance='primary'>
          {state.is_redirecting? (
            <Spinner size='tiny' />
          ): (
            <span>Access cPanel</span>
          )}
        </Button>

      </Card>
    )
  }

}