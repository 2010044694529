import accountStore from "../../redux/Account/AccountRedux";
import { Capabilities } from "../Capabilities/Capabilities";
import Global_Error from "../GlobalError/GlobalError";
import Log from "../Log/Log";
import Backend_Request from "../Request/BackendRequest";

export default class Initial_Load {

  public static async perform_initial_load(): Promise<void> {
    try {
      Log.dev_log("--> Starting Initial Load");

      const request = new Backend_Request({
        endpoint: "v2/account/get_info",
        requestBody: {},
        verbose: true
      });
      const result = await request.perform();
      if(result.is_success) {
        console.log(result);
        const accountInfo = result.response_data.account_info;
        accountStore.dispatch({
          type: "ACCOUNT_UPDATE",
          payload: {
            first_name: accountInfo.first_name,
            last_name: accountInfo.last_name,
            email_address: accountInfo.email,
            organisation_name: accountInfo.organisation_name,
            phone_number: accountInfo.phone_number
          }
        });
      }

      // load the user session capabilities from the backend...
      const loadCapabilities = await Capabilities.load_capabilities();

      Log.dev_log("--> Initial Load Completed");

    }
    catch(error) {
      const globalError = new Global_Error()
      globalError.display_user_visible_error({
        error_title: "We detected a problem",
        error_content: "We have detected an issue while loading 121 Digital Console. Please try refreshing the page or you can continue to use console as normal; If you detect an issue, then please contact support@121digital.co.uk"
      });

      globalError.dialog_events.on("dialog_close", () => {
        globalError.dialog_events.removeAllListeners();
      });

      console.error(error);
    }
  }

}