import { createStore } from 'redux';
import rootReducer from './Reducers';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage: storage, // Use AsyncStorage for React we b
  whitelist: ['global_auth']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer);
export default store;
export const persistor = persistStore(store);

export const await_persistor_rehydration = new Promise((resolve, reject) => {
  persistor.subscribe(() => {
    if (store.getState()._persist.rehydrated) {
      resolve(true);
    }
  });
});

