import { DefaultButton, Panel, TextField, ThemeSettingName } from '@fluentui/react';
import { Button, Dialog, DialogActions, DialogBody, DialogSurface, DialogTitle, Drawer, DrawerBody, DrawerHeader, DrawerHeaderTitle, Field, Input, Text } from '@fluentui/react-components';
import React from 'react';
import Spacer from '../../Spacer/Spacer';
import { Dismiss24Regular } from "@fluentui/react-icons";
import { Website_Basic_Data } from '../../../lib/Website/Website';
import websiteEditorReducer from '../../../redux/WebsiteEditor/WebsiteEditorReducers';
import websiteEditorStore from '../../../redux/WebsiteEditor/WebsiteEditorRedux';

export interface WebsiteEditorGlobalSettingsDrawer_Props {
  display: boolean,
  onClose: any
}

export default class WebsiteEditorGlobalSettingsDrawer extends React.Component <WebsiteEditorGlobalSettingsDrawer_Props> {

  private styles: any;
  private display: boolean;

  private _is_mounted: boolean = false;

  public constructor(props: any) {
    super(props);

    // set display state...
    this.display = this.props.display;

    this.state = {
      is_submitting: false,
      is_open: this.props.display,

      site_data: {
        title: "",
        subtitle: "",
        domain_name: ""
      } as Website_Basic_Data

    };
    this.styles = {
      dialogSurface: {
        width: '50%',
        // height: '100%',
        maxHeight: 'none',
        // right: 0,
        // position: 'absolute',
        // top: 0,
        overflow: 'hidden',
      }
    };

    this.load_data();
  }

  public on_dialog_close = () => {
    this.props.onClose();
  }

  public on_cancel = () => {
    // TODO: impliment calcelation logic...
    this.on_dialog_close();
  }

  public componentDidMount(): void {
    this._is_mounted = true;
    this.load_data();
  }

  public componentWillUnmount(): void {
    this._is_mounted = false;
  }

  public load_data = async () => {
    if(this._is_mounted) {
      const reduxState = websiteEditorStore.getState();

      this.setState({
        site_data: reduxState.site_data.basic_data
      });
    }
  }

  public render() {
    const state = this.state as any;

    return (
      <Drawer
        position='end'
        size='medium'
        // type={}
        separator
        open={this.props.display}
        // onOpenChange={(_, { open }) => {})}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => {
                  this.on_dialog_close();
                }}
              />
            }
          >
            General Website Settings
          </DrawerHeaderTitle>
        </DrawerHeader>

        <DrawerBody>
          
          <Spacer size='25px' />
          <Text weight='bold'>Generic Settings</Text>
          <Spacer size='15px' />

          <Field label={"Website Title"}>
            <Input value={state.site_data.title} />
          </Field>

          <Spacer size='15px' />

          <Field label={"Website Subtitle"}>
            <Input value={state.site_data.subtitle} />
          </Field>

          <Spacer size='25px' />
          <Text weight='bold'>Domain Settings</Text>
          <Spacer size='15px' />

          <Field label={"Primary Website Address"}>
            <Input disabled={true} value={state.site_data.domain_name} />
          </Field>


          <Spacer size='55px' />
          <Button appearance='primary'>Save Changes</Button>
          <Button onClick={this.on_cancel} style={{marginLeft: 15}}>Cancel</Button>

        </DrawerBody>
      </Drawer>

    );
  }

}