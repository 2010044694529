import React from 'react';
import Authentication_Service from './../../lib/Authentication/AuthenticationService';

export default class ProtectedAuthRoute extends React.Component {

  public constructor(props: any) {
    super(props);

    // get the initial auth state...
    const isAuthenticated = Authentication_Service.is_authenticated();


    console.log(isAuthenticated);
    // check that we are authenticated...
    if(isAuthenticated) {
      window.location.assign("/dashboard");
    }
  }

  public render() {
    return (
      <div>
        {this.props.children}
      </div>
    );
  }

}