import { Website_Basic_Data, Website_Page } from "../../lib/Website/Website";

const rootState = {
  site_data: {
    basic_data: {
      title: "",
      subtitle: "",
      domain_name: ""
    } as Website_Basic_Data,

    pages: [] as Array<Website_Page>
  }
};

const websiteEditorReducer = (state = rootState, action: any) => {
  switch(action.type) {
    
    case "SITE_DATA_UPDATE":
      return { ...state, site_data: action.payload}

    default:
      return state;
  }
}

export default websiteEditorReducer;