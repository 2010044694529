import React from 'react';
import { Nav, INavLink, INavLinkGroup } from '@fluentui/react/lib/Nav';
import { getTheme, IconButton, Image, mergeStyles, Stack, Text } from '@fluentui/react';
import {ReactComponent as BrandLogoSvg} from './../../assets/branding/brand.svg';
import HeaderProfile from './HeaderProfile';
import Authentication_Service from '../../lib/Authentication/AuthenticationService';
import Backend_Request from '../../lib/Request/BackendRequest';
import store from '../../redux/ReduxStore';
import accountStore from '../../redux/Account/AccountRedux';

export default class Header extends React.Component {

  private styles:any = {};

  constructor(props: any) {
    super(props);

    const authenticated = Authentication_Service.is_authenticated();


    const reduxState = accountStore.getState();
    this.state = {
      displayHeader: authenticated,
      first_name: reduxState.account.first_name,
      last_name: reduxState.account.last_name
    };

    this.styles = {
      nav: {
        backgroundColor: '#F4F4F4',
        padding: 0,
        width: '100vw',
        height: 55,
        // lineHeight: 55,
        paddingTop: 15,
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 999
      },

      contentWrapper: {
        marginTop: 5
      }
    }

    this.load_account_data();
 
  }

  public load_account_data = async () => {

  }

  public render() {
    const navLinks: INavLink[] = [
      { key: 'home', name: 'Home', url: '#' },
      { key: 'about', name: 'About', url: '#about' },
      { key: 'services', name: 'Services', url: '#services' },
      { key: 'contact', name: 'Contact', url: '#contact' }
    ];
    
    const navLinkGroups: INavLinkGroup[] = [
      {
        links: navLinks
      }
    ];


    // Choose a predefined color from FluentColors


    if((this.state as any).displayHeader) {
      const state = this.state as any;

      return (
        <div style={this.styles.nav}>
          <BrandLogoSvg height={40} style={{marginLeft: -380, marginTop: 2.5}}/>

          <div style={{float: "right", paddingRight: 15}}>
            <div style={this.styles.contentWrapper}>
              {/* <IconButton
                iconProps={{
                  iconName: "Ringer"
                }}
              />
              <IconButton
                iconProps={{
                  iconName: "Help"
                }}
              /> */}

              <HeaderProfile 
                first_name={state.first_name} 
                last_name={state.last_name}
                onNavigateOut={(url: string) => {
                  window.location.assign(url);
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    else {
      return(<div />);
    }
  }

}