/**
 * 121 Digital Console is a trading name of 121 Digital Services Limited
 *
 * @category    Domains
 * @package     121 Digital Core
 * @subpackage  121 Domain Name Manager
 * @author      James Gibbons <jgibbons@121digital.co.uk>
 * @license     https://www.121digital.co.uk/console-software-license 121 Digital Software License
 * @link        https://www.121digital.co.uk
 */

/**
 * Disclaimer: This source code and its comments are the intellectual property
 * of 121 Digital Console, a trading name of 121 Digital Services Limited. It may not be used, modified,
 * or distributed without explicit permission from 121 Digital Console, a trading name of 121 Digital Services Limited.
 */

import Backend_Request from "../Request/BackendRequest";

/**
 * Interface defining the structure of a billing method.
 */
export interface Billing_Method_Type {
  card_brand: string,
  expiration_date: string,
  last_four_digits: string,
  name_on_card: string
}

/**
 * Class representing Billing_Method with static methods to create and retrieve billing methods.
 */
export default class Billing_Method {

  /**
   * Create a new billing method.
   * 
   * @param paymentMethodId - The ID of the payment method.
   * @param nameOnCard - The name on the card.
   * @returns Promise<boolean> - Returns true if the billing method is successfully created, otherwise throws an error.
   */
  public static async create_billing_method(paymentMethodId: string, nameOnCard: string): Promise<boolean> {
    try {
      const request = new Backend_Request({
        endpoint: "v2/billing/methods/create",
        requestBody: {
          pm_id: paymentMethodId,
          name_on_card: nameOnCard
        },
        verbose: true
      });
      const result = await request.perform();
      if (result.is_success) {
        return true;
      } else {
        throw "Cannot load accounts billing methods.";
      }
    } catch (error) {
      throw error;
    }
  }

  /**
   * Retrieve all billing methods.
   * 
   * @returns Promise<Array<Billing_Method_Type>> - Returns an array of billing methods.
   */
  public static async get_billing_methods(): Promise<Array<Billing_Method_Type>> {
    try {
      const request = new Backend_Request({
        endpoint: "v2/billing/methods/get",
        requestBody: {},
        verbose: false
      });
      const result = await request.perform();
      if (result.is_success) {
        const billingMethods = result.response_data.billing_methods;
        return billingMethods;
      } else {
        throw "Cannot load accounts billing methods.";
      }
    } catch (error) {
      throw error;
    }
  }
}
