import React from 'react';
import {
  makeStyles,
  Button,
  Caption1,
  Text,
  tokens,
  Subtitle1,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@fluentui/react-components";
import { MoreHorizontal20Regular } from "@fluentui/react-icons";
import { Card, CardHeader, CardPreview } from "@fluentui/react-components";
import accountStore from '../../redux/Account/AccountRedux';
import Billing from '../../lib/Billing/Billing';
import ViewLoader from '../ViewLoader/ViewLoader';

export default class BillingCard extends React.Component {

  public constructor(props:any) {
    super(props);

    const reduxState = accountStore.getState();
    this.state = {
      billing_report: {
        total_order_count: 0
      },
      is_loading: true
    };

    this.load_data();
  };

  public load_data = async () => {
    try {
      const billingOverviewReport = await Billing.get_billing_overview();
      this.setState({
        is_loading: false,
        billing_report: billingOverviewReport
      });

    }
    catch(error) {
      throw error;
    }
  }

  public render() {
    const state = this.state as any;

    return (
      <div>
          <section>
          <Card>
            <CardHeader
              header={<Text weight="semibold">Account Billing Overview</Text>}
              description={
                <Caption1></Caption1>
              }
              action={
                <Button
                  appearance="transparent"
                  icon={<MoreHorizontal20Regular />}
                  aria-label="More options"
                  onClick={() => {
                  
                  }}
                />
              }
            />

            {state.is_loading? (
               <ViewLoader height="180px" marginLeft='0' message='Loading Billing Overview' />
            ) : (
            <div>
            <Table>
              <TableBody>

                <TableRow>
                  <TableCell>
                    <b>Total Orders</b>
                  </TableCell>
                  <TableCell>
                    {state.billing_report.total_order_count}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <b>Active Orders</b>
                  </TableCell>
                  <TableCell>
                    {state.billing_report.active_order_count}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <b>Inactive Orders</b>
                  </TableCell>
                  <TableCell>
                    {state.billing_report.inactive_order_count}
                  </TableCell>
                </TableRow>

              </TableBody>
            </Table>

            <br /> <br />

              <Button  
              style={{width: 170}}
              onClick={() => {
          
              }}
            >Billing Center</Button>

            </div>
            )}

          </Card>
        </section>
      </div>
    );
  }

}