import { combineReducers, createStore } from 'redux';
import rootReducer from './GlobalErrorReducers';
import { persistStore, persistReducer } from 'redux-persist';

const persistConfig = {
  key: 'root',
};

const globalErrorStore = createStore(rootReducer);
export default globalErrorStore;


