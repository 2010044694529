import React from 'react';
import ErrorDialog from './ErrorDialog';
import globalErrorStore from '../../redux/GlobalError/GlobalErrorRedux';
import { ThemeSettingName } from '@fluentui/react';

export default class ErrorDialogProvider extends React.Component {

  public constructor(props: any) {
    super(props);

    // very generic initial state values incase of redux issues..
    this.state = {
      display: false,
      error_title: "Error Title",
      error_body: "Error content."
    };

    this.get_error_dialog_state();
  }

  public get_error_dialog_state = (): void => {
    const initialState = globalErrorStore.getState();
    const initialGlobalError = initialState.global_error;
    this.state = {
      display: initialGlobalError.display,
      error_title: initialGlobalError.error_title,
      error_body: initialGlobalError.error_body
    };

    // watch for changes in the redux state and check if we have an error.
    globalErrorStore.subscribe(() => {
      const reduxState = globalErrorStore.getState();
      const globalError = reduxState.global_error;

      // check if we need to init the escape key listener...
      if(globalError.display) {
        this.create_escape_key_listener();
      }

      this.setState({
        display: globalError.display,
        error_title: globalError.error_title,
        error_body: globalError.error_body
      });

      // this.display_error();
    });
  }

  public on_dialog_close = (): void => {
    globalErrorStore.dispatch({
      type: "GLOBAL_ERROR_UPDATE",
      payload: {
        error_title: "",
        error_body: "",
        display: false
      }
    });
  }

  public create_escape_key_listener() {
    document.addEventListener("keydown", this.handle_keydown_event)
  }

  private handle_keydown_event = (event: KeyboardEvent): void => {
    if(event.key === 'Escape' || event.key === 'Esc') {
      // cleanup the listener and execute the dialog close function...
      document.removeEventListener("keydown", this.handle_keydown_event);
      this.on_dialog_close();
    }
  }

  public render() {
    const state = this.state as any;

    if(state.display) {
      return (
        <ErrorDialog 
          display={true}
          errorBody={state.error_body}
          errorTitle={state.error_title}

          onClose={this.on_dialog_close}
        />
      );
    }
    else {
      return (<div />);
    }
  }

}