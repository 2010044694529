import React from 'react';
import {
  Dialog,
  DialogSurface,
  DialogBody,
  DialogActions,
  Button,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import ViewLoader from '../../ViewLoader/ViewLoader';
import Website from '../../../lib/Website/Website';
import Backend_Request from '../../../lib/Request/BackendRequest';

export interface ElementorEmbedDialog_Props {
  display: boolean,
  onClose: any,
  url: string,
  page: any,
  pageId: number
}

export default class ElementorEmbedDialog extends React.Component<ElementorEmbedDialog_Props> {
  private dialogSurfaceRef: React.RefObject<HTMLDivElement>;
  private styles: any;

  private elementorUrl = "";

  public constructor(props: any) {
    super(props);

    this.dialogSurfaceRef = React.createRef();

    this.state = {
      editor_loading: true,
      editor_url: "",
    };

    this.styles = {
      dialogSurface: {
        width: 'calc(100vw)',
        height: 'calc(100vh)',
        maxWidth: '100vw',
        maxHeight: '100vh',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '0', // Remove border-radius
      },
      dialogBody: {
        flex: 1, // Allow the body to take up all available space
        overflow: 'hidden', // Prevent scrolling within the body
        marginTop: '3rem', // Space for dialog actions
        display: 'flex',
      },
      dialogActions: {
        position: 'fixed',
        top: 0,
        zIndex: 9999,
        width: '100%', // Full width
        padding: '1rem',
        borderBottom: '1px solid #ccc',
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'space-between', // Space out label and button
        alignItems: 'center', // Vertically center content
        boxSizing: 'border-box',
      },
      iframeStyle: {
        width: '100%',
        height: '100%',
        border: 'none',
        marginTop: 17,
        flex: 1, // Ensure the iframe grows to fill the space
      },
      label: {
        marginRight: 'auto', // Pushes the label to the left
        fontWeight: 'bold', // Optional: for better visibility
      }
    };

    this.load_data();

  }

  public componentDidMount(): void {
    this.load_data();
  }

  public componentDidUpdate(prevProps: ElementorEmbedDialog_Props) {
    if (this.props.display && !prevProps.display) {
      this.preventBodyScroll(true);
      this.focusDialog();
    } else if (!this.props.display && prevProps.display) {
      this.preventBodyScroll(false);
    }
    
    this.elementorUrl = `http://localhost:8888/wordpress/wp-admin/post.php?post=${this.props.pageId}&action=elementor`
  }

  private preventBodyScroll(shouldPrevent: boolean) {
    document.body.style.overflow = shouldPrevent ? 'hidden' : 'auto';
  }

  private focusDialog() {
    if (this.dialogSurfaceRef.current) {
      this.dialogSurfaceRef.current.focus();
    }
  }

  private get_elementor_url() {
    return `http://localhost:8888/wordpress/wp-admin/post.php?post=${this.props.pageId}&action=elementor`;
  }

  public load_data = async () => {
    try {
      // const websiteEditorUrl = Website.get_site_page_editor_url(2,1);
      const request = new Backend_Request({
        endpoint: "v2/websites/access_editor",
        requestBody: {
          website_id: 2
        },
        verbose: true
      });
      const result = await request.perform();
      if(result.is_success) {
        const responseData = result.response_data;
        if(responseData.can_access) {
          const redirectUrl = responseData.redirect_url;
          this.setState({
            editor_url: redirectUrl,
            editor_loading: false
          });

          const state = this.state as any;
          console.log(state.elementorUrl);

          // after the session has been established, we will redirect the user to the editor page...
          setTimeout(() => {
            this.setState({
              editor_url: state.elementorUrl
            });
          }, 500);

        }
      }

    }
    catch(error) {
      throw error;
    }
  }

  public on_close = () => {
    this.props.onClose();
  }

  public render() {
    const state = this.state as any;
    console.log(this.elementorUrl);

    this.elementorUrl = this.get_elementor_url();

    return (
      <Dialog open={this.props.display}>
        <DialogSurface style={this.styles.dialogSurface} ref={this.dialogSurfaceRef}>
          <DialogActions style={this.styles.dialogActions}>
            <div style={this.styles.label}>Page Editor - {this.props.page.page_title}</div>
            <Button 
              icon={<Dismiss24Regular />} 
              appearance="subtle" 
              onClick={this.on_close} 
              aria-label="Close"
            />
          </DialogActions>
          <DialogBody style={this.styles.dialogBody}>
            {state.editor_loading? (
              <ViewLoader marginLeft='0px' message='Loading Website Page Editor' />

            ) : (
              <iframe style={this.styles.iframeStyle} src={this.elementorUrl} />
            )}
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  }
}
