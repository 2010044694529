import { DefaultButton, Icon, Panel, PanelType, PrimaryButton, TextField, initializeIcons } from '@fluentui/react';
import React from 'react';
import Spacer from '../Spacer/Spacer';
import { Button } from '@fluentui/react-components';

initializeIcons();

export interface DomainNameserverPanelProps {
  isOpen: boolean,
  nameservers: {
    nameserverRecordName: string,
    value: string
  },

  onDismiss?: any | void | null
};

export class DomainNameserverEditPanel extends React.Component <DomainNameserverPanelProps> {

  constructor(props: any) {
    super(props);

    if(this.props.nameservers) {
      const nameservers = this.props.nameservers as any;

      this.state = {
        nameserver_1: nameservers[0],
        nameserver_2: nameservers[1],
        nameserver_3: nameservers[2],
        nameserver_4: nameservers[3]
      };
    }
  }

  private onPanelDismiss = () => {
    if(typeof this.props.onDismiss !== 'undefined') {
      this.props.onDismiss();
    }
  }

  public render() {
    const state = this.state as any;

    return (
      <Panel
        isOpen={this.props.isOpen}
        onDismiss={this.onPanelDismiss}
        closeButtonAriaLabel="Close"
        headerText="Update Nameservers"
      >
        <Spacer size='15px' />
        
        <TextField label='Nameserver 1' value={state.nameserver_1.value} />
        <TextField label='Nameserver 2' value={state.nameserver_2.value}/>
        <TextField label='Nameserver 3' value={state.nameserver_3.value}/>
        <TextField label='Nameserver 4' value={state.nameserver_4.value}/>

        <Spacer size='15px' />
        <p>
          Caution, this setting may
          interupt services that your domain name is connected to.
        </p>

        <Button>
          <Icon iconName="Undo" styles={{ root: { paddingRight: 5, color: '#0078d4' } }} />
          Reset to default nameservers
        </Button>

        <Spacer size='15px' />
        <PrimaryButton>Save Changes</PrimaryButton>
        <DefaultButton onClick={this.onPanelDismiss} style={{marginLeft: 5}}>Cancel</DefaultButton>
      </Panel>
    );
  }

}