import { combineReducers, createStore } from 'redux';
import rootReducer from './AccountReducers';
import { persistStore, persistReducer } from 'redux-persist';

const persistConfig = {
  key: 'root',
};

const accountStore = createStore(rootReducer);
export default accountStore;


