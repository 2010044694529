import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Text, DialogSurface } from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';

interface BillingConfirmMethodDeleteModalProps {
  display: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const BillingConfirmMethodDeleteModal: React.FC<BillingConfirmMethodDeleteModalProps> = ({
  display,
  onConfirm,
  onCancel
}) => {
  return (

    <Dialog open={display}>
    <DialogSurface>
      <DialogTitle>Confirm billing method deletion</DialogTitle>
      <DialogContent>
        <p>
          Confirming this action will remove the billing method from your account. If
          your alternative billing methods are unchargable, this could result in service
          suspension.
        </p>

        <br />
      </DialogContent>
      <DialogActions>
        <Button type="button" onClick={onConfirm} appearance='primary'>Confirm Action</Button>
        <Button onClick={onCancel}>Close</Button>
      </DialogActions>
    </DialogSurface>
  </Dialog>
  );
};

export default BillingConfirmMethodDeleteModal;
