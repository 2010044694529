import websiteEditorStore from "../../redux/WebsiteEditor/WebsiteEditorRedux";
import Log from "../Log/Log";
import Backend_Request from "../Request/BackendRequest";

export interface Website_Page {
  title: string,
  slug: string
};

export interface Website_Basic_Data {
  title: string,
  subtitle: string,
  domain_name: string
};

export interface Website_Data_Redux_Cache {
  basic_data: {
    title: string,
    subtitle: string
  },
  pages: Array<Website_Page>,

}

export default class Website {

  public static async load_website_data(): Promise<any> {
    return {};
  }

  public static async load_website_data_into_redux(site_id: number): Promise<void> {
    try {
      const pages = await Website.get_pages(site_id);
      const siteData = await Website.get_site_basic_data(site_id);

      // const pages = websiteBasicData.pages;

      // dispatch the loaded data into the redux store...
      websiteEditorStore.dispatch({
        type: "SITE_DATA_UPDATE",
        payload: {
          basic_data: {
            title: siteData.title,
            subtitle: siteData.subtitle,
            domain_name: siteData.domain_name
          },
          pages: pages
        }
      });

    }
    catch(error) {
      throw error;
    } 
  }

  public static get_website_id_from_encoded_param(): number | null {
    try {
      const url = window.location.pathname;
      const parts = url.split('/');
      
      // Ensure the URL has enough parts
      if (parts.length < 3) {
        console.error('URL is missing expected parts');
        return null; // Or handle the error appropriately
      }
      
      const websiteIdentifier = parts[2];
      
      // Validate that the identifier is a valid base64 string
      let decodedUrlParam: any;
      try {
        const base64Pattern = /^[A-Za-z0-9+/=]+$/;
        if (!base64Pattern.test(websiteIdentifier)) {
          throw new Error('Invalid base64 string');
        }
        decodedUrlParam = JSON.parse(atob(websiteIdentifier));
      } catch (error) {
        console.error('Failed to decode or parse URL parameter:', error);
        return null; // Or handle the error appropriately
      }
      
      // // Validate the parsed object
      // if (!decodedUrlParam || typeof decodedUrlParam.site_id !== 'string') {
      //   console.error('Decoded parameter does not contain a valid site_id');
      //   return null; // Or handle the error appropriately
      // }
      
      const siteId = decodedUrlParam.site_id;
      return siteId;

    }
    catch(error) {
      window.location.assign("/websites");
      return null;
    }
  }

  public static async website_is_valid(siteId: number): Promise<boolean> {
    try {
      return false;

    }
    catch(error) {
      throw error;
    }
  }

  public static async get_site_page_editor_url(siteId: number, pageId: number): Promise<any> {
    try {
      // load the websites order meta from the backend...
      const request = new Backend_Request({
        endpoint: "v2/website/pages/editor/get",
        requestBody: {
          website_id: siteId,
          page_id: pageId,
          verbose: true
        },
        verbose: true
      });
      const result = await request.perform();
      console.log(result);

      if(result.is_success) {

      }
      else {

      }

    }
    catch(error) {
      throw error;
    }
  }

  public static async get_site_basic_data(siteId: number): Promise<Website_Basic_Data> {
    try {
      // load the websites order meta from the backend...
      const request = new Backend_Request({
        endpoint: "v2/website/meta/get",
        requestBody: {
          website_id: siteId,
          verbose: false
        },
        verbose: false
      });
      const result = await request.perform();

      if(result.is_success) {
        const responseData = result.response_data;
        // console.log(responseData);

        return {
          title: responseData.site_data.site_title,
          subtitle: responseData.site_data.site_subtitle,
          domain_name: responseData.site_data.website_address
        }
      }
      else {
        return {
          title: "",
          subtitle: "",
          domain_name: "121digital.co.uk"
        };

      }
    }
    catch(error) {
      throw error;
    }
  }

  public static async get_pages(siteId: number): Promise<Array<Website_Page>> {
    try {
      // load the websites order meta from the backend...
      const request = new Backend_Request({
        endpoint: "v2/website/pages/get",
        requestBody: {
          website_id: siteId,
        },
        verbose: true
      });
      const result = await request.perform();

      if(result.is_success) {
        const responseData = result.response_data;
        return responseData.wp_remote_pages;

      }
      else {
        return [];
      }

    }
    catch(error) {
      throw error;
    }
  }

}