import React from 'react';
import SafeArea from '../../components/SafeArea/SafeArea';
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react';
import Website from '../../lib/Website/Website';
import ViewLoader from '../../components/ViewLoader/ViewLoader';
import WebsiteEditorPagesCard from '../../components/WebsiteEditor/WebsiteEditorPagesCard/WebsiteEditorPagesCard';
import WebsiteEditorCommandBar from '../../components/WebsiteEditor/WebsiteEditorCommandBar/WebsiteEditorCommandBar';
import websiteEditorStore from '../../redux/WebsiteEditor/WebsiteEditorRedux';
import { Button } from '@fluentui/react-components';
import Spacer from '../../components/Spacer/Spacer';
import ElementorEmbedDialog from '../../components/WebsiteEditor/ElementorEmbedDialog/ElementorEmbedDialog,';

export default class WebsiteEditorView extends React.Component {
  private breadcrumbItems: IBreadcrumbItem[];
  private styles: any;

  public _is_mounted: boolean = false;

  public constructor(props: any) {
    super(props);

    // parse the URL params..
    // const url = window.location.pathname;
    // const parts = url.split('/');
    // const websiteIdentifier = parts[2];
    // const decodedUrlParam = JSON.parse(atob(websiteIdentifier));
    // const siteId = decodedUrlParam.site_id;

    const websiteId = Website.get_website_id_from_encoded_param();
    if(websiteId) {

    }
    else {
      window.location.assign("/websites");
    }

    this.breadcrumbItems = [];
    this.styles = {};
    this.state = {
      data_loading: true,
      site_id: websiteId,

      pages: []
    };

    this.load_data();
  }

  public async componentDidMount(): Promise<void> {
    this._is_mounted = true;
    await this.load_data();
  }

  public async componentWillUnmount(): Promise<void> {
    this._is_mounted = false;
  }

  public load_data = async () => {
    try {
      const state = this.state as any;
      // const pages = await Website.get_pages(state.site_id);
      // const siteData = await Website.get_site_basic_data(state.site_id);

      const reduxStateUpdate = await Website.load_website_data_into_redux(state.site_id);

      // this will load and reload the data upon rehyrdation of redux state...
      websiteEditorStore.subscribe(() => {
        const reduxState = websiteEditorStore.getState();
        const siteData = reduxState.site_data.basic_data;
        const pages = reduxState.site_data.pages;
  
        // define basic breadcrumb data...
        this.breadcrumbItems = [
          { text: 'Home', key: 'home', onClick: () => (this.props as any).navigate("/dashboard") },
          { text: 'Websites', key: 'manage_websites', isCurrentItem: false, onClick: () => (this.props as any).navigate("/websites")},
          { text: siteData.title, key: 'current_site', isCurrentItem: false },
          { text: 'Editor', key: 'editor', isCurrentItem: true },
        ];
  
        if(this._is_mounted) {
          this.setState({
            data_loading: false,
            pages: pages,
            site_data: siteData,

            display_elementor_dialog: false,
          });
        }
      });

    }
    catch(error) {
      throw error;
    }
  }

  public render() {
    const state = this.state as any; 

    return (
      <SafeArea fullscreen={true}>

      {state.data_loading? (
        <ViewLoader message='Loading Website Editor' />

      ) : (
        <div style={{width: "100%"}}>
          <div style={this.styles.breadcrumb_wrapper}>
            <Breadcrumb
              items={this.breadcrumbItems}
              maxDisplayedItems={5}
              ariaLabel="Breadcrumb with static width"
              overflowAriaLabel="More links"
            />
          </div>

          <Spacer size="15px" />

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h1>Website Editor ({state.site_data.title})</h1>
            <Button
              onClick={() => {
                (this.props as any).navigate("/websites");
              }}
            >Exit Website Editor</Button>
          </div>
          
          
          <div style={{marginTop: -10}}>
            <WebsiteEditorCommandBar />
          </div>

          <div style={this.styles.commandBar}>

          </div>

          <br />

          <div style={{display: "flex", marginLeft: 1}}>
            <div style={{width: "33%"}}>
              <WebsiteEditorPagesCard 
                pages={state.pages}
                onPageEditorSelect={(pageId: number) => {
                  // this.setState({
                  //   display_elementor_dialog: true
                  // })
                }}
              />
            </div>
          </div>

        </div>
      )}
      </SafeArea>
    );
  }

}