import React, { useState, useEffect } from 'react';
import { Button, Drawer, DrawerBody, DrawerHeader, DrawerHeaderTitle, Field, Input, Text, Spinner } from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import Spacer from '../Spacer/Spacer';
import { StripeCardElementOptions, loadStripe } from '@stripe/stripe-js';
import env from '../../env/env';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import Billing_Method from '../../lib/Billing/BillingMethod';

// Load Stripe outside of the component to avoid recreating the object on every render
const stripePromise = loadStripe(env.STRIPE_PUBLIC_KEY);

interface BillingAddCreditCardProps {
  display: boolean;
  onClose: (hasCreated: boolean) => void;
}

const BillingAddCreditCard: React.FC<BillingAddCreditCardProps> = ({ display, onClose }) => {
  return (
    <Elements stripe={stripePromise}>
      <BillingAddCreditCard_Component display={display} onClose={onClose} />
    </Elements>
  );
};

const BillingAddCreditCard_Component: React.FC<BillingAddCreditCardProps> = ({ display, onClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [nameOnCard, setNameOnCard] = useState('');
  const [isCardComplete, setIsCardComplete] = useState(false);
  const [isFormComplete, setIsFormComplete] = useState(false);

  useEffect(() => {
    const isComplete = nameOnCard.trim() !== '' && isCardComplete;
    setIsFormComplete(isComplete);
  }, [nameOnCard, isCardComplete]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement)!,
    });

    if (error) {
      console.error(error);
      setLoading(false);
      return;
    }

    // create the new billing method against the API endpoints... 
    Billing_Method.create_billing_method(paymentMethod.id, nameOnCard).then((paymentMethodCreated) => {
      onClose(true);
      setLoading(false);
    });
  };

  const handleDialogClose = () => {
    onClose(false);
  };

  return (
    <Drawer position='end' size='medium' separator open={display}>
      <DrawerHeader>
        <DrawerHeaderTitle
          action={
            <Button
              appearance='subtle'
              aria-label='Close'
              icon={<Dismiss24Regular />}
              onClick={handleDialogClose}
            />
          }
        >
          Add Billing Method
        </DrawerHeaderTitle>
      </DrawerHeader>

      <DrawerBody>
        <Spacer size='25px' />
        <Text weight='bold'>Credit / Debit Card Details</Text>
        <Spacer size='15px' />

        <form onSubmit={handleSubmit}>
          <Field label='Name on card'>
            <Input
              value={nameOnCard}
              onChange={(e) => setNameOnCard(e.target.value)}
              placeholder='Enter name on card'
            />
          </Field>

          <Spacer size='15px' />

          <Field label='Card Details'>
            <div style={{ border: '1px solid', padding: 15, borderRadius: '4px' }}>
              <CardElement
                onChange={(event) => {
                  setIsCardComplete(event.complete);
                }}
                options={{
                  hidePostalCode: true
                } as StripeCardElementOptions}
              />
            </div>
          </Field>

          <Spacer size='55px' />
          <Button
            disabled={!isFormComplete || loading}
            type='submit'
            appearance='primary'
          >
            {loading ? <Spinner label='Processing...' /> : 'Save Billing Method'}
          </Button>
          <Button onClick={handleDialogClose} style={{ marginLeft: 15 }}>
            Cancel
          </Button>
        </form>
      </DrawerBody>
    </Drawer>
  );
};

export default BillingAddCreditCard;
