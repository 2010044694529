import React from 'react';
import SafeArea from '../../components/SafeArea/SafeArea';
import BlockedFeature from '../../components/BlockedFeature/BlockedFeature';

export default class SupportView extends React.Component {

  public constructor(props: any) {
    super(props);
  }

  public render() {
    return (
      <SafeArea>
        <BlockedFeature />
      </SafeArea>
    );
  }

}