import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react';
import React from 'react';

export interface DomainNameserverPanelProps {
  nameservers: Array<{
    nameserverRecordName: string,
    value: string
  }>
}

export class DomainNameserversPanel extends React.Component <DomainNameserverPanelProps> {

  private styles: any = {};

  constructor(props: any) {
    super(props);

    this.styles = {
      panelItem: {
        width: "100%"
      }
    }
  }

  public render() {
    const state = (this.state as any);

    return (
      <div style={this.styles.panelItem}>
        <h2>Domain Nameservers</h2>

        <DetailsList
          items={this.props.nameservers}
          isHeaderVisible={false}
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.fixedColumns}
        />
      </div>
    );
  }

}