import capabilitiesStore from "../../redux/Capabilities/CapabilitiesRedux";
import Backend_Request from "../Request/BackendRequest";

export enum Capability_Types {
  dashboard = "dashboard",
  websites = "websites",
  billing = "billing",
  support = "suppport",
  domains = "domains",
  partner = "partner",
  account = "account",
  hosting = "hosting"
};

/**
 * Class for handling capabilities related operations.
 */
export class Capabilities {

  /**
   * Loads capabilities from the core API and updates the Redux store.
   * @returns {Promise<void>}
   */
  public static async load_capabilities(): Promise<void> {
    try {
      // Load the capabilities from the core API...
      const request = new Backend_Request({
        endpoint: "v2/capabilities/get",
        requestBody: {}
      });
      const result = await request.perform();
      if (result.is_success) {
        const responseData = result.response_data;
        capabilitiesStore.dispatch({
          type: "CAPABILITIES_UPDATE",
          payload: responseData.capabilities
        });

        await Capabilities.has_capability(Capability_Types.support);

      } else {
        throw new Error("Could not load capabilities from core.");
      }

    } catch (error) {
      throw error;
    }
  }

  /**
   * Checks if a specific capability exists in the Redux store.
   * @param {Capability_Types} capability - The capability to check.
   * @returns {boolean} - Returns true if the capability exists, false otherwise.
   */
  public static has_capability(capability: Capability_Types): boolean {
    try {
      const reduxState = capabilitiesStore.getState();
      const capabilityString = capability.toString() as string;

      if (reduxState.capabilities.length > 0) {
        const capabilities = reduxState.capabilities;
        let hasCapability = false;

        for (let activeCapability of capabilities) {
          if (activeCapability == capability) {
            hasCapability = true;
          }
        }

        return hasCapability;

      } else {
        return false;
      }

    } catch (error) {
      throw error;
    }
  }

}